<template>
    <div class="page-kpzbj-list">
        <NavBar :active="activeNav" v-if="showNav"></NavBar>
        <div class="page-title"><em></em>科普直播间</div>
<!--        <div class="" style="font-size: 24px;text-align: center;padding: 40px 0;margin-bottom: 100px;">建设中，敬请期待...</div>-->
        <a-list :dataSource="list" class="content-list">
            <template #renderItem="item, index">
                <div class="item">
                    <div class="left-content">
                        <img :src="item.ShowImgUrl"/>
                    </div>
                    <div class="right-content">
                        <h2 class="item-title">
                            <span v-if="item.SectionId === 100065">直播中</span>
                            {{item.Title}}</h2>
                        <div class="info item-info">
                            <p class="info-title">直播简介：</p>
                            <p class="info-content">{{item.Brief}}</p>
                        </div>
                        <div class="info item-time">
                            <p class="info-title">直播时间：</p>
                            <p class="info-content">{{item.PulishTime}}</p>
                        </div>
                        <div class="to-detail">
                            <a-button :type="item.SectionId === 100065 ? 'danger' : 'primary'" @click="toDetail(item)">{{item.SectionId === 100065 ? '立即观看':'查看回放'}}</a-button>
                        </div>
                    </div>
                </div>
            </template>
        </a-list>
        <template>
            <a-pagination
                    v-model="params.page"
                    :total="listTotal"
                    :pageSize="params.limit"
                    hideOnSinglePage
                    class="page-pagination"
                    @change="changePageNum"
            />
        </template>
    </div>
</template>

<script>
    import {getZBJListApi} from "../../server";
    import NavBar from "../../components/NavBar";

    export default {
        name: "KpwspList",
        components: {NavBar},
        data() {
            return {
                activeNav: '4',
                listTotal: 0,
                showNav: true,
                params: {
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 18
                },
                list: [],
            }
        },
        methods: {
            async getList(type) {
                if (type === 'refresh') this.params.page = 1;
                const _data = await getZBJListApi(this.params);
                const data = _data.Data;
                if (data.list) {
                    this.listTotal = data.count;
                    this.list = data.list
                    console.log(this.list)
                }
            },
            toDetail(item) {
                window.open(item.PCLinkUrl);
            },
            changePageNum(num) {
                this.params.page = num;
                this.getList();
            },
        },
        created() {
            this.getList()
        },
        // activated() {
        //     if (!this.$route.meta.isBack) {
        //
        //     }
        //     this.$route.meta.isBack = false;
        // },
        // beforeRouteEnter(to, from, next) {
        //     if (from.path == '/kpwsp_detail') {
        //         to.meta.isBack = true;
        //     } else {
        //         to.meta.isBack = false;
        //     }
        //     next();
        // },

    }
</script>

<style scoped lang="scss">
    .page-kpzbj-list {
        background: #fff;

        .page-title {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            padding-left: 16px;
            margin-top: 60px;
            margin-bottom: 20px;
            font-size: 22px;
            color: #333333;
            font-weight: 700;
            text-align: left;

            em {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 3px;
                background: #2a8ad3;
                border-radius: 2px;
            }
        }

        .content-list {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
            .item {
                display: flex;
                box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
                margin-bottom: 30px;
                .left-content{
                    width: 726px;
                    height: 408px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .right-content{
                    flex: 1;
                    padding: 20px;
                    text-align: left;
                    h2{
                        font-size: 28px;
                        color: #2786D9;
                        margin-top: 10px;
                        margin-bottom: 20px;
                        font-weight: bold;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        span{
                            display: inline-block;
                            background: #FF314C;
                            color: #fff;
                            padding: 2px 6px;
                            border-radius: 4px;
                            font-size: 14px;
                            vertical-align: middle;
                            font-weight: normal;
                        }
                    }
                    .item-info{
                        font-weight: bold;
                        font-size: 14px;
                        margin-bottom: 20px;
                        .info-content{
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .item-time{
                        font-weight: bold;
                        font-size: 14px;
                        margin-bottom: 20px;
                    }
                    .to-detail{
                        text-align: right;
                    }
                }
            }
        }

        .page-pagination {
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: center;
        }
    }
</style>