<template>
    <div class="page-kpzsk-detail">
        <NavBar active="3"></NavBar>
        <div class="page-menu"><span>首页</span> / {{data.BigSectionName}} / {{data.SectionName}}</div>
        <div class="detail-content">
            <div class="page-title">{{data.Title}}</div>
            <div class="page-tips">
                <div class="tags">
                    <span v-for="_item in data.Tags && data.Tags.split(',')" v-if="_item">{{_item}}</span>
                </div>
                <span class="page-source" v-if="data.Source">来源：{{data.Source}}</span>
                <span class="page-date">{{data.PulishDate}}</span>
            </div>
            <div class="page-content" v-html="data.Content"></div>
        </div>


    </div>
</template>

<script>
    import {getKPZSKDetailApi} from "../../server";
    import NavBar from "../../components/NavBar";

    export default {
        name: "KpzskDetail",
        components: {NavBar},
        data() {
            return {
                data: {
                    Tags: ''
                }
            }
        },
        methods: {
            async getDetail() {
                const data = await getKPZSKDetailApi({
                    Id: this.$route.query.id
                });
                this.data = data.Data;
            },
        },
        created() {
            this.getDetail();
        }
    }
</script>

<style scoped lang="scss">
    .page-kpzsk-detail{
        text-align: left;
        background: #f2f2f2;
        padding-bottom: 40px;
        .page-menu{
            width: 1200px;
            margin: 0 auto;
            padding: 30px 0;
            font-size: 16px;
            color: #999999;
            line-height: 21px;
            font-weight: 700;
            span{
                color: #2786D9;
            }
        }
        .detail-content{
            width: 1200px;
            margin: 0 auto;
            background: #fff;
            border-radius: 4px;
            padding: 32px;
            .page-title{
                font-size: 28px;
                color: #333333;
                font-weight: 700;
                padding: 20px 0;
            }
            .page-tips{
                padding-bottom: 20px;
            }
            .page-title,.page-tips{
                padding-left: 30px;
            }

            .tags{
                display: inline-block;
                margin-right: 30px;
                span {
                    display: inline-block;
                    padding: 2px 6px;
                    background: #f6f6f6;
                    font-size: 14px;
                    color: #999999;
                    font-weight: 700;
                    margin-right: 12px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                }
            }

            .page-source {
                color: #999;
                margin-right: 30px;
            }
            .page-date {
                font-size: 14px;
                color: #999999;
                font-weight: 700;
            }
            .page-content{
                border-top:1px solid rgba(39, 134, 217, 0.24);
                padding-top: 24px;

            }
        }
    }
</style>