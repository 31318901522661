<template>
    <div class="NewDetail">
        NewDetail
    </div>
</template>

<script>
    export default {
        name: "NewDetail",
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style scoped lang="scss">

</style>
