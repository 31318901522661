import { request } from './api'

/**
 * 科普在身边列表
 */
export const getKPZBJListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.InfoList`,
    method: 'POST',
    normal: true,
    params
  })
}

/**
 * 科普在身边列表
 */
export const getKPZSBListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.InfoList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普微视频列表
 */
export const getKPWSPListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.VideoList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普知识库列表
 */
export const getKPZSKListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.KnowledgeList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普悦读汇列表
 */
export const getKPYDHListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.BookList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普基地行列表
 */
export const getKPJDXListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.BaseList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 食药科普站列表
 */
export const getSYKPZListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.StationList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普直播间
 */
export const getZBJListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.LiveList`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普在身边详情
 */
export const getKPZSBDetailApi = (params) => {
  return request({
    url: `/api/SJKP.Material.InfoDetail`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普微视频详情
 */
export const getKPWSPDetailApi = (params) => {
  return request({
    url: `/api/SJKP.Material.VideoDetail`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普知识库详情
 */
export const getKPZSKDetailApi = (params) => {
  return request({
    url: `/api/SJKP.Material.KnowledgeDetail`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普悦读汇详情
 */
export const getKPYDHDetailApi = (params) => {
  return request({
    url: `/api/SJKP.Material.BookDetail`,
    method: 'POST',
    normal: true,
    params
  })
}


/**
 * 科普基地行详情
 */
export const getKPJDXDetailApi = (params) => {
  return request({
    url: `/api/SJKP.Material.BaseDetail`,
    method: 'POST',
    normal: true,
    params
  })
}

/**
 * 推荐列表
 */
export const getRecommendListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.RecommendList`,
    method: 'POST',
    normal: true,
    params
  })
}



/**
 * 全局关键字搜索
 */
export const getKeywordsSearchListApi = (params) => {
  return request({
    url: `/api/SJKP.Material.SearchList`,
    method: 'POST',
    normal: true,
    params
  })
}




/**
 * 健康上海列表
 */
export const getJKSHListApi = (params) => {
  return request({
    url: `/api/JKSH.Material.VideoList`,
    method: 'POST',
    normal: true,
    params
  })
}




/**
 * 健康上海详情
 */
export const getJKSHDetailApi = (params) => {
  return request({
    url: `/api/JKSH.Material.VideoDetail`,
    method: 'POST',
    normal: true,
    params
  })
}




