<template>
    <div class="page-kpydh-list">
        <NavBar active="5"></NavBar>
        <div class="page-title"><em></em>科普悦读汇</div>
        <a-list  :grid="{ gutter: 16, column: 2 }" :dataSource="list" class="content-list">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a-card class="list-item1" @click="toDetail(item)">
                    <div class="item-content">
                        <div class="img-box">
                            <img :src="item.ShowImgUrl"/>
                        </div>
                        <div class="item-info">
                            <div class="item-title">{{item.Title}}</div>
                            <div class="item-desc">{{item.Brief}}</div>
                            <div class="item-other">
                                <div class="item-source">{{item.Source}}</div>
                                <div class="item-date">{{item.PulishDate}}</div>
                            </div>
                        </div>
                    </div>
                </a-card>
            </a-list-item>
        </a-list>
        <template>
            <a-pagination
                    v-model="params.page"
                    :total="listTotal"
                    :pageSize="params.limit"
                    hideOnSinglePage
                    class="page-pagination"
                    @change="changePageNum"
            />
        </template>
    </div>
</template>

<script>
    import {getKPYDHListApi} from "../../server";

    import NavBar from "../../components/NavBar";
    import Item1 from "../../components/Item1";
    export default {
        name: "KpwspList",
        components:{Item1, NavBar},
        data() {
            return {
                selectedType: {},
                listTotal: 0,
                params: {
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 10
                },
                list: [],
            }
        },
        methods: {
            async getList(type) {
                if (type === 'refresh') this.params.page = 1;
                const _data = await getKPYDHListApi(this.params);
                const data = _data.Data;
                if (data.list) {
                    this.listTotal = data.count;
                    this.list = data.list
                    console.log(this.list)
                }
            },
            changePageNum(num) {
                this.params.page = num;
                this.getList();
            },
            toDetail(item) {
                this.$router.push({name: 'kpydh_detail', query: {id: item.Id}});
            }
        },
        created() {
            this.getList()
        }
    }
</script>

<style scoped lang="scss">
    .page-kpydh-list{
        background: #fff;
        .page-title{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            padding-left: 16px;
            margin-bottom: 20px;
            margin-top: 50px;
            font-size: 22px;
            color: #333333;
            font-weight: 700;
            text-align: left;
            em{
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 3px;
                background: #2a8ad3;
                border-radius: 2px;
            }
        }
        .content-list {
            width: 1200px;
            margin: 0 auto;
            .list-item1 {
                text-align: left;
                margin-bottom: 30px;
                cursor: pointer;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: 0px 0px 5px 0px rgba(163, 157, 157,0.3);

                /deep/ .ant-card-body {
                    padding: 0;
                }
                .item-content{
                    display: flex;
                    padding: 30px;
                }
                .img-box {
                    width: 180px;
                    height: 180px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-info {
                    flex: 1;
                    padding: 0 16px;
                    .item-title {
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        font-size: 18px;
                        height: 64px;
                        color: #333333;
                        font-weight: 700;
                        line-height: 1.7;
                        margin-bottom: 10px;
                    }
                    .item-desc{
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 3;
                        height: 66px;
                        margin-bottom: 20px;
                    }
                    .item-other {
                        display: flex;

                        .item-source{
                            width: 50%;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #666666;
                            font-weight: 600;
                        }
                        .item-date{
                            width: 50%;
                            text-align: right;
                            font-weight: bold;
                            color: #999;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .page-pagination {
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: center;
        }
    }
</style>