<template>
    <div class="page-footer">
        <div class="page-footer-content">
            <div class="footer-left">
                <div class="logo">
<!--                    <img src="../assets/logo.png"/>-->
                    <span class="logo-text">上海市病媒生物防治实训基地科普平台</span>
                </div>
            </div>
            <div class="footer-middle">
                <div class="middle-info">
                    <div class="about-me">
                        <h4>联系我们</h4>
                        <p>电话：600000000-0000</p>
                        <p>地址：上海市徐汇区肇嘉浜路301号</p>
                        <p>邮编：200032</p>
                    </div>
                    <div class="friend-link">
                        <h4>友情链接</h4>
                        <p><a href="https://scjgj.sh.gov.cn/" target="_blank">上海市药监局</a></p>
                        <p><a href="http://stcsm.sh.gov.cn/" target="_blank">上海市xxx医院</a></p>
                        <p><a href="http://www.scjgkp.com/" target="_blank">上海市预防医学会</a></p>
                        <p><a href="http://www.spaq.sh.cn/" target="_blank">上海疾病防控中心</a></p>
                    </div>
                </div>
                <div class="web-info">
<!--                    <p style="margin-right: 20px;">主办单位：上海市市场监督管理局</p>-->
                    <p style="margin-right: 20px;">主办单位：上海市爱国卫生运动委员会办公室  东方网  上海市疾病预防控制中心  上海市健康促进中心  上海市交通大学公共卫生学院</p>
                    <p>技术支持：东方网 东方社区信息苑</p>
                    <p>沪ICO备2024654155号-2  &nbsp;<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=310104022254811"><img src="../assets/gongan.png"/> 沪公网安备 310104022254811</a>&nbsp; 政府网站标识码：3222222222</p>
                </div>
            </div>
            <div class="footer-right">
                <div class="qr-code">
                    <h4>扫一扫关注</h4>
                    <p><img src="../assets/qrCode.png"/></p>
                </div>
            </div>
        </div>
        <div class="footer-bar"></div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {}
        },
        methods: {
            toUrl(url){
                this.$router.push({name: url})
            }
        }
    }
</script>

<style scoped lang="scss">
@import "../components/reset";
    .page-footer{
        position: relative;
        width: 100%;
        height: 360px;
        /*max-width: 1920px;*/
        background: #0b1d2c;
        color: #8E93A1;
        margin: 0 auto;
        padding-top: 40px;
        h4{
            font-weight: bold;
            color: #fff;
            margin-bottom: 30px;
            font-size: 18px;
            text-align: left;
            padding-left: 30px;
        }
        .page-footer-content{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            .footer-left{
                text-align: left;
                width: 250px;
                background: url("../assets/bottom11.png") no-repeat bottom center;
                .logo{
                    padding-top: 20px;
                    text-align: center;
                    /*font-family: 'PangMenZhengDao','SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;*/
                    font-weight: bold;
                    .logo-text{
                        font-size: 14px;
                        color: #fff;
                    }
                }

            }
            .footer-middle{
                flex: 1;
                height: 312px;
                .middle-info{
                    display: flex;
                    .link-map,.about-me,.friend-link{
                        flex: 1;
                        p{
                           margin-bottom: 16px;
                            font-size: 14px;
                            line-height: 1.5;
                        }
                    }
                    .link-map p{
                        margin-bottom: 10px;
                    }
                    .link-map p span{
                        cursor: pointer;
                    }
                    .about-me{
                        padding-left: 60px;
                        p{
                            padding-left: 30px;
                            text-align: left;
                        }
                    }
                    .friend-link{
                        a{
                            color: #8E93A1;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                        p{
                            text-align: left;
                            padding-left: 30px;
                        }
                    }
                }
                .web-info{
                    font-size: 12px;
                    margin-top: 30px;
                    line-height: 1.7;
                    a{
                        text-decoration: none;
                        color: #8E93A1;
                        *{vertical-align: middle}
                        &:hover{
                            text-decoration: underline;
                            color: #2a8ad3;
                        }
                        img{
                            width: 15px;
                        }
                    }
                }
            }
            .footer-right{
                width: 200px;
                /*background: url("../assets/bottom2.png") no-repeat bottom center;*/
                /*background-size: 136px;*/
                .qr-code{
                    h4{
                        text-align: center;
                        padding: 0;
                    }
                    p{
                        width: 120px;
                        height: 120px;
                        margin: 0 auto;
                        padding: 13px;
                        background: rgba(39,134,217,0.24);
                        border-radius: 6px;
                        img{
                            width: 94px;
                            height: 94px;
                        }
                    }
                }
            }
        }
        .footer-bar{
            position: absolute;
            bottom: 0;
            width: 100%;
            max-width: 1920px;
            height: 8px;
            background-image: linear-gradient(135deg, #63B82F 0%, #2F8DEF 52%, #32499F 100%);
        }
    }
</style>
