<template>
    <div class="index-title">
        <div class="icon-box">
            <a-icon class="iconfont" :type="data.iconType" />
        </div>
        <div class="icon-title">
            <h4>{{data.title}}<img src="../assets/icon-star.svg"/></h4>
            <p>{{data.titleEn}}</p>
        </div>
        <div class="middle-line"></div>
        <div class="more" @click="toMore"><span>MORE /</span> 查看更多</div>
    </div>
</template>

<script>
    export default {
        name: "IndexTitle",
        props:{
            data:{
                type: Object,
                default:()=>{}
            }
        },
        data() {
            return {}
        },
        methods: {
            toMore(){
                this.$router.push({name: this.data.moreName})
            }
        }
    }
</script>

<style scoped lang="scss">
    .index-title{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        .icon-box{
            width: 44px;
            height: 44px;
            padding-top: 6px;
            background: #4FBBEE;
            border-radius: 4px;
            margin-right: 16px;
            margin-top: 6px;
            /*line-height: 60px;*/
            .iconfont{
                color: #fff;
                font-size: 30px;
            }
        }
        .icon-title{
            width: 170px;
            text-align: left;
            h4{
                font-size: 24px;
                color: #2786D9;
                font-weight: 700;
                margin: 0;
                img{
                    margin-left: 10px;
                }
            }
            p{
                font-size: 12px;
                color: #999999;
                font-weight: 700;
                margin: 0;
            }
        }
        .middle-line{
            flex: 1;
            height: 30px;
            border-bottom: 1px dashed rgba(39,134,217,0.68);
        }
        .more{
            width: 140px;
            padding-top: 20px;
            cursor: pointer;
           span{
               opacity: 0.5;
           }
            font-size: 14px;
            color: #2786D9;
            font-weight: 700;
        }
    }
</style>