<template>
    <div class="page-kpwsp-list">
        <NavBar :active="activeNav" v-if="showNav"></NavBar>
        <TopTypeList :list="typeList" @change="changeType" :active="params.SectionId"></TopTypeList>
<!--        <div class="page-title"><em></em>{{selectedType.name || '全部'}}</div>-->
        <Tags :list="tagList" @change="changeTag" :active="params.Tag"></Tags>
        <a-list :grid="{ gutter: 16, column: 3 }" :dataSource="list" class="content-list">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <Item1 :data="item"></Item1>
            </a-list-item>
        </a-list>
        <template>
            <a-pagination
                    v-model="params.page"
                    :total="listTotal"
                    :pageSize="params.limit"
                    hideOnSinglePage
                    class="page-pagination"
                    @change="changePageNum"
            />
        </template>
    </div>
</template>

<script>
    import {getKPWSPListApi} from "../../server";

    const typeList = [
        {
            name: '上海市监',
            id: '100056',
            imgUrl: require('../../assets/icon-zscb.svg'),
            activeImgUrl: require('../../assets/icon-zscb-active.svg')
        },
        {
            name: '江苏市监',
            id: '100057',
            imgUrl: require('../../assets/icon-safe.svg'),
            activeImgUrl: require('../../assets/icon-safe-active.svg')
        },
        {
            name: '浙江市监',
            id: '100058',
            imgUrl: require('../../assets/icon-kpsy.svg'),
            activeImgUrl: require('../../assets/icon-kpsy-active.svg')
        },
        {
            name: '安徽市监',
            id: '100059',
            imgUrl: require('../../assets/icon-zcpj.svg'),
            activeImgUrl: require('../../assets/icon-zcpj-active.svg')
        }
    ];
    const tagList = [
        "全部",
        "计量",
        "标准化",
        "认证认可",
        "检验检测",
        "质量提升",
        "产品质量",
        "食品安全",
        "药品安全",
        "特种设备安全",
        "消费维权",
        "知识产权保护",
        "反垄断", "反不正当竞争", "广告监管", "化妆品安全", "登记注册", "综合执法", "公平竞争", "信用监管", "价格监管", "网络交易监管"];
    import NavBar from "../../components/NavBar";
    import TopTypeList from "../../components/TopTypeList";
    import Tags from "../../components/Tags";
    import Item1 from "../../components/Item1";

    export default {
        name: "KpwspList",
        components: {Item1, Tags, TopTypeList, NavBar},
        data() {
            return {
                typeList,
                selectedType: {},
                tagList,
                activeNav: '2',
                listTotal: 0,
                showNav: true,
                activeTag: '全部',
                params: {
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 18
                },
                list: [],
            }
        },
        methods: {
            async getList(type) {
                if (type === 'refresh') this.params.page = 1;
                const _data = await getKPWSPListApi(this.params);
                const data = _data.Data;
                if (data.list) {
                    this.listTotal = data.count;
                    this.list = data.list
                    console.log(this.list)
                }
            },
            changePageNum(num) {
                this.params.page = num;
                this.getList();
            },
            changeType(item) {
                if (item) {
                    this.selectedType = item;
                    this.params.SectionId = item.id;
                    this.params.Tag = '';
                    this.getList('refresh');
                }
            },
            changeTag(tag) {
                this.params.Tag = tag;
                this.params.SectionId = '0';
                this.getList('refresh');
            }
        },
        created() {
            this.getList()
        },
        activated() {
            if (!this.$route.meta.isBack) {
                this.getList('refresh');
            }
            this.$route.meta.isBack = false;
        },
        beforeRouteEnter(to, from, next) {
            if (from.path == '/kpwsp_detail') {
                to.meta.isBack = true;
            } else {
                to.meta.isBack = false;
            }
            next();
        }
    }
</script>

<style scoped lang="scss">
    .page-kpwsp-list {
        background: #fff;

        .page-title {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            padding-left: 16px;
            margin-bottom: 20px;
            font-size: 22px;
            color: #333333;
            font-weight: 700;
            text-align: left;

            em {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 3px;
                background: #2a8ad3;
                border-radius: 2px;
            }
        }

        .content-list {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
        }

        .page-pagination {
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: center;
        }
    }
</style>