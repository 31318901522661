<template>
    <a-card class="list-item1" @click="toDetail">
        <div class="img-box">
            <img :src="data.ShowImgUrl"/>
            <a-icon type="play-circle" class="icon-play"/>
            <span class="video-time">{{data.Duration}}</span>
        </div>
        <div class="item-info">
            <div class="item-title">{{data.Title}}</div>
            <div class="item-other">
                <div class="item-tags">
                    <span v-for="(item,index) in data.Tags.split(',')" v-show="item" :key="index">{{item}}</span>
                </div>
                <div class="item-source">{{data.Source}}</div>
            </div>
        </div>
    </a-card>
</template>

<script>
    export default {
        name: "Item1",
        props: {
            data: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {}
        },
        methods: {
            toDetail(id) {
                this.$router.push({name: 'kpwsp_detail', query: {id: this.data.Id}});
            }
        }
    }
</script>

<style scoped lang="scss">
    .list-item1 {
        text-align: left;
        margin-bottom: 30px;
        cursor: pointer;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px 0px 5px 0px rgba(163, 157, 157,0.3);

        /deep/ .ant-card-body {
            padding: 0;
        }

        .img-box {
            height: 216px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .icon-play {
                position: absolute;
                left: 20px;
                bottom: 20px;
                color: #fff;
                font-size: 30px;
            }

            .video-time {
                position: absolute;
                right: 20px;
                bottom: 20px;
                color: #fff;
                font-size: 16px;
            }
        }

        .item-info {
            padding: 16px;

            .item-title {
                white-space: normal;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
                font-size: 18px;
                height: 64px;
                color: #333333;
                font-weight: 700;
                line-height: 1.7;
                margin-bottom: 10px;
            }

            .item-other {
                display: flex;
                height: 66px;
                .item-tags {
                    flex: 1;
                    span {
                        display: inline-block;
                        padding: 2px 6px;
                        background: #f6f6f6;
                        font-size: 14px;
                        color: #999999;
                        font-weight: 700;
                        margin-right: 12px;
                        margin-bottom: 6px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                    }

                }
                .item-source{
                    width: 40%;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #666666;
                    font-weight: 600;
                }
            }
        }
    }
</style>