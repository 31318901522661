<template>
    <div class="page-kpzsk-list">
        <NavBar active="3"></NavBar>
        <TopTypeList :list="typeList" @change="changeType" :active="params.SectionId"></TopTypeList>
        <div class="page-title"><em></em>{{selectedType.name || '全部'}}</div>
        <Tags :list="tagList" @change="changeTag" :active="params.Tag"></Tags>
        <a-list  :grid="{ gutter: 16, column: 3 }" :dataSource="list" class="content-list">
            <template #renderItem="item">
                <div class="item-content" @click="toDetail(item)">
                    <h4 class="item-title">{{item.Title}}</h4>
                    <div class="item-info">
                        <div class="item-img-box">
                            <img :src="item.ShowImgUrl"/>
                        </div>
                        <div class="item-right-info">
                            <div class="item-desc">{{item.Brief}}</div>
                            <div class="item-other">
                                <div class="item-tags">
                                    <span v-for="(_item,_index) in item.Tags.split(',')" v-show="_item" :key="_index">{{_item}}</span>
                                </div>
                                <div class="item-source" v-if="item.Source">来源：{{item.Source}}</div>
                            </div>
                        </div>
                        <div class="item-date">{{item.PulishDate}}</div>
                    </div>
                </div>
            </template>



<!--            <a-list-item slot="renderItem" slot-scope="item, index">-->
<!--                <a-card class="list-item1" @click="toDetail(item)">-->
<!--                    <div class="img-box">-->
<!--                        <img :src="item.ShowImgUrl"/>-->
<!--                    </div>-->
<!--                    <div class="item-info">-->
<!--                        <div class="item-title">{{item.Title}}</div>-->
<!--                        <div class="item-other">-->
<!--                            <div class="item-tags">-->
<!--                                <span v-for="(_item,_index) in item.Tags.split(',')" v-show="_item" :key="_index">{{_item}}</span>-->
<!--                            </div>-->
<!--                            <div class="item-date">{{item.PulishDate}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </a-card>-->
<!--            </a-list-item>-->
        </a-list>
        <template>
            <a-pagination
                    v-model="params.page"
                    :total="listTotal"
                    :pageSize="params.limit"
                    hideOnSinglePage
                    class="page-pagination"
                    @change="changePageNum"
            />
        </template>
    </div>
</template>

<script>
    const tagList = [
        "全部",
        "计量",
        "标准化",
        "认证认可",
        "检验检测",
        "质量提升",
        "产品质量",
        "食品安全",
        "药品安全",
        "特种设备安全",
        "消费维权",
        "知识产权保护",
        "反垄断", "反不正当竞争", "广告监管", "化妆品安全", "登记注册", "综合执法", "公平竞争", "信用监管", "价格监管", "网络交易监管"];
    const typeList = [
        {
            name: '上海市监',
            id: '100060',
            imgUrl: require('../../assets/icon-zscb.svg'),
            activeImgUrl: require('../../assets/icon-zscb-active.svg')
        },
        {
            name: '江苏市监',
            id: '100061',
            imgUrl: require('../../assets/icon-safe.svg'),
            activeImgUrl: require('../../assets/icon-safe-active.svg')
        },
        {
            name: '浙江市监',
            id: '100062',
            imgUrl: require('../../assets/icon-kpsy.svg'),
            activeImgUrl: require('../../assets/icon-kpsy-active.svg')
        },
        {
            name: '安徽市监',
            id: '100063',
            imgUrl: require('../../assets/icon-zcpj.svg'),
            activeImgUrl: require('../../assets/icon-zcpj-active.svg')
        }
    ];
    import {getKPZSKListApi} from "../../server";

    import NavBar from "../../components/NavBar";
    import TopTypeList from "../../components/TopTypeList";
    import Tags from "../../components/Tags";
    import Item1 from "../../components/Item1";
    export default {
        name: "KpwspList",
        components:{Item1, Tags, TopTypeList, NavBar},
        data() {
            return {
                tagList,
                typeList,
                selectedType: {},
                listTotal: 0,
                params: {
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 18
                },
                list: [],
            }
        },
        methods: {
            async getList(type) {
                if (type === 'refresh') this.params.page = 1;
                const _data = await getKPZSKListApi(this.params);
                const data = _data.Data;
                if (data.list) {
                    this.listTotal = data.count;
                    this.list = data.list
                    console.log(this.list)
                }
            },
            changeTag(tag) {
                this.params.Tag = tag;
                this.params.SectionId = '';
                this.getList('refresh');
            },
            changePageNum(num) {
                this.params.page = num;
                this.getList();
            },
            toDetail(item) {
                this.$router.push({name: 'kpzsk_detail', query: {id: item.Id}});
            },
            changeType(item) {
                if (item) {
                    this.selectedType = item;
                    this.params.SectionId = item.id;
                    this.params.Tag = '';
                    this.getList('refresh');
                }
            },
        },
        created() {
            this.getList()
        },
        activated() {
            if(!this.$route.meta.isBack) {
                this.getList('refresh');
            }
            this.$route.meta.isBack = false;
        },
        beforeRouteEnter(to, from, next) {
            if(from.path == '/kpzsk_detail') {
                to.meta.isBack = true;
            }else{
                to.meta.isBack = false;
            }
            next();
        }
    }
</script>

<style scoped lang="scss">
    .page-kpzsk-list{
        background: #fff;
        .page-title{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            padding-left: 16px;
            margin-bottom: 20px;
            margin-top: 50px;
            font-size: 22px;
            color: #333333;
            font-weight: 700;
            text-align: left;
            em{
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 3px;
                background: #2a8ad3;
                border-radius: 2px;
            }
        }
        .content-list {
            width: 1200px;
            margin: 0 auto;
            margin-top: 24px;
            .item-content {
                cursor: pointer;
                text-align: left;
                border-bottom: 1px solid #ddd;
                padding: 12px 0;

                .item-title {
                    color: #333;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.7;
                    margin-bottom: 12px;
                }

                .item-info {
                    display: flex;

                    .item-img-box {
                        width: 112px;
                        height: 80px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                        }
                    }

                    .item-right-info {
                        flex: 1;
                        padding-left: 16px;

                        .item-desc {
                            white-space: normal;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            height: 52px;
                            font-size: 16px;
                            color: #666666;
                            font-weight: 400;
                        }

                        .item-other {
                            .item-tags, .item-source {
                                display: inline-block;

                            }

                            .item-tags {
                                span {
                                    display: inline-block;
                                    padding: 2px 6px;
                                    background: #f6f6f6;
                                    font-size: 14px;
                                    color: #999999;
                                    font-weight: 700;
                                    margin-right: 12px;
                                    border: 1px solid #ccc;
                                    border-radius: 4px;
                                }
                            }

                            .item-source {
                                color: #999;
                                margin-left: 20px;
                            }
                        }
                    }

                    .item-date {
                        width: 200px;
                        text-align: right;
                        padding-right: 12px;
                        font-size: 14px;
                        color: #999999;
                        font-weight: 700;
                    }
                }
            }



            /*.list-item1 {*/
            /*    text-align: left;*/
            /*    margin-bottom: 30px;*/
            /*    cursor: pointer;*/
            /*    border: 1px solid #ccc;*/
            /*    border-radius: 4px;*/
            /*    box-shadow: 0px 0px 5px 0px rgba(163, 157, 157,0.3);*/

            /*    /deep/ .ant-card-body {*/
            /*        padding: 0;*/
            /*    }*/

            /*    .img-box {*/
            /*        height: 216px;*/
            /*        position: relative;*/
            /*        img {*/
            /*            width: 100%;*/
            /*            height: 100%;*/
            /*        }*/
            /*    }*/

            /*    .item-info {*/
            /*        padding: 16px;*/
            /*        height: 173px;*/
            /*        .item-title {*/
            /*            white-space: normal;*/
            /*            display: -webkit-box;*/
            /*            -webkit-box-orient: vertical;*/
            /*            overflow: hidden;*/
            /*            -webkit-line-clamp: 2;*/
            /*            font-size: 18px;*/
            /*            height: 64px;*/
            /*            color: #333333;*/
            /*            font-weight: 700;*/
            /*            line-height: 1.7;*/
            /*            margin-bottom: 10px;*/
            /*        }*/

            /*        .item-other {*/
            /*            display: flex;*/
            /*            height: 66px;*/
            /*            .item-source{*/
            /*                width: 40%;*/
            /*                font-size: 14px;*/
            /*                overflow: hidden;*/
            /*                text-overflow: ellipsis;*/
            /*                white-space: nowrap;*/
            /*                color: #666666;*/
            /*                font-weight: 600;*/
            /*            }*/
            /*            .item-tags {*/
            /*                flex: 1;*/
            /*                span {*/
            /*                    display: inline-block;*/
            /*                    padding: 2px 6px;*/
            /*                    background: #f6f6f6;*/
            /*                    font-size: 14px;*/
            /*                    color: #999999;*/
            /*                    font-weight: 700;*/
            /*                    margin-right: 12px;*/
            /*                    margin-bottom: 6px;*/
            /*                    border: 1px solid #ccc;*/
            /*                    border-radius: 4px;*/
            /*                }*/

            /*            }*/
            /*            .item-date{*/
            /*                width: 50%;*/
            /*                text-align: right;*/
            /*                font-weight: bold;*/
            /*                color: #999;*/
            /*                font-size: 14px;*/
            /*            }*/
            /*        }*/
            /*    }*/
            /*}*/
        }
        .page-pagination {
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: center;
        }
    }
</style>