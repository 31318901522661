<template>
    <div class="page-kpzsb-list">
        <NavBar active="1"></NavBar>
<!--        <TopTypeList :list="typeList" @change="changeType"></TopTypeList>-->
<!--        <div class="page-title"><em></em>{{selectedType.name || '全部'}}</div>-->
<!--        <Tags :tagList="tagList" @change="changeTag"></Tags>-->
        <template>
            <a-list item-layout="horizontal" :dataSource="list" class="content-list">
                <template #renderItem="item">
                    <div class="item-content" @click="toDetail(item.Id)">
                        <h4 class="item-title">{{item.Title}}</h4>
                        <div class="item-info">
                            <div class="item-img-box">
                                <img :src="item.ShowImgUrl"/>
                            </div>
                            <div class="item-right-info">
                                <div class="item-desc">{{item.Brief}}</div>
                                <div class="item-other">
<!--                                    <div class="item-tags">-->
<!--                                        <span v-for="_item in item.Tags.split(',')" v-if="_item">{{_item}}</span>-->
<!--                                    </div>-->
                                    <div class="item-source" v-if="item.Source">来源：{{item.Source}}</div>
                                </div>
                            </div>
                            <div class="item-date">{{item.PulishDate}}</div>
                        </div>
                    </div>
                </template>

            </a-list>
            <template>
                <a-pagination
                        v-model="params.page"
                        :total="listTotal"
                        :pageSize="params.limit"
                        hideOnSinglePage
                        class="page-pagination"
                        @change="changePageNum"
                />
            </template>
        </template>
    </div>
</template>

<script>
    import Tags from "../../components/Tags";

    const typeList = [
        {
            name: '知识传播',
            id: '100018',
            imgUrl: require('../../assets/icon-zscb.svg'),
            activeImgUrl: require('../../assets/icon-zscb-active.svg')
        },
        {
            name: '安全教育',
            id: '100019',
            imgUrl: require('../../assets/icon-safe.svg'),
            activeImgUrl: require('../../assets/icon-safe-active.svg')
        },
        {
            name: '科普素养',
            id: '100020',
            imgUrl: require('../../assets/icon-kpsy.svg'),
            activeImgUrl: require('../../assets/icon-kpsy-active.svg')
        },
        {
            name: '政策普及',
            id: '100021',
            imgUrl: require('../../assets/icon-zcpj.svg'),
            activeImgUrl: require('../../assets/icon-zcpj-active.svg')
        }
    ];
    const tagList = [
        "全部",
        "计量",
        "标准化",
        "认证认可",
        "检验检测",
        "质量提升",
        "产品质量",
        "食品安全",
        "药品安全",
        "特种设备安全",
        "注册登记",
        "消费维权",
        "知识产权保护",
        "反垄断",
        "反不正当竞争",
        "信用管理",
        "广告监管",
        "其他"];
    import TopTypeList from "../../components/TopTypeList";
    import NavBar from "../../components/NavBar";
    import {getKPZSBListApi} from "../../server";

    export default {
        name: "KpzsbList",
        components: {Tags, TopTypeList, NavBar},
        data() {
            return {
                typeList,
                selectedType: {},
                tagList,
                listTotal: 0,
                params: {
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 20
                },
                list: [],
            }
        },
        methods: {
            async getList(type) {
                if (type === 'refresh'){
                    this.params.page = 1;
                    this.list = [];
                }
                const _data = await getKPZSBListApi(this.params);
                const data = _data.Data;
                if (data.list) {
                    this.listTotal = data.count;
                    this.list = data.list
                }
            },
            changePageNum(num) {
                this.params.page = num;
                this.getList();
            },
            changeType(item) {
                if (item) {
                    this.selectedType = item;
                    this.params.SectionId = item.id;
                    this.getList('refresh');
                }
            },
            changeTag(tag) {
                this.params.Tag = tag;
                this.getList('refresh');
            },
            toDetail(id) {
                this.$router.push({name: 'kpzsb_detail', query: {id: id}});
            }
        },
        created() {
            this.getList()
        },
        activated() {
            if(!this.$route.meta.isBack) {
                this.getList('refresh');
            }
            this.$route.meta.isBack = false;
        },
        beforeRouteEnter(to, from, next) {
            if(from.path == '/kpzsb_detail') {
                to.meta.isBack = true;
            }else{
                to.meta.isBack = false;
            }
            next();
        }
    }
</script>

<style scoped lang="scss">
    @import "../../components/reset";

    .page-kpzsb-list {
        background: #fff;
        .page-title{
            width: 1200px;
            margin: 0 auto;
            position: relative;
            padding-left: 16px;
            margin-bottom: 20px;
            font-size: 22px;
            color: #333333;
            font-weight: 700;
            text-align: left;
            em{
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 3px;
                background: #2a8ad3;
                border-radius: 2px;
            }
        }
        .content-list {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
        }

        .item-content {
            cursor: pointer;
            text-align: left;
            border-bottom: 1px solid #ddd;
            padding: 12px 0;

            .item-title {
                color: #333;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.7;
                margin-bottom: 12px;
            }

            .item-info {
                display: flex;

                .item-img-box {
                    width: 112px;
                    height: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                }

                .item-right-info {
                    flex: 1;
                    padding-left: 16px;

                    .item-desc {
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        height: 52px;
                        font-size: 16px;
                        color: #666666;
                        font-weight: 400;
                    }

                    .item-other {
                        .item-tags, .item-source {
                            display: inline-block;

                        }

                        .item-tags {
                            span {
                                display: inline-block;
                                padding: 2px 6px;
                                background: #f6f6f6;
                                font-size: 14px;
                                color: #999999;
                                font-weight: 700;
                                margin-right: 12px;
                                border: 1px solid #ccc;
                                border-radius: 4px;
                            }
                        }

                        .item-source {
                            color: #999;
                            margin-left: 20px;
                        }
                    }
                }

                .item-date {
                    width: 200px;
                    text-align: right;
                    padding-right: 12px;
                    font-size: 14px;
                    color: #999999;
                    font-weight: 700;
                }
            }
        }

        .page-pagination {
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: center;
        }
    }
</style>