<template>
    <div class="tags-content">
        <div class="tags-list">
            <span class="tags-item" v-for="(item,index) in tagList" :key="index" @click="changeItem(item)"
                  :class="getActive(item)">{{item}}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Tags",
        props: {
            active: {
                type: String,
                default: '全部'
            },
            list: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                activeTag: '',
                tagList: [
                    "全部",
                    "计量",
                    "标准化",
                    "认证认可",
                    "检验检测",
                    "质量提升",
                    "产品质量",
                    "食品安全",
                    "药品安全",
                    "特种设备安全",
                    "消费维权",
                    "知识产权保护",
                    "反垄断", "反不正当竞争", "广告监管", "化妆品安全", "登记注册", "综合执法", "公平竞争", "信用监管", "价格监管", "网络交易监管"
                ]
            }
        },
        watch: {
            active: {
                handler(newValue) {
                    this.activeTag = newValue;
                },
                immediate: true
            }
        },
        methods: {
            getActive(item) {
                if ((this.activeTag == '' && item == '全部') || this.activeTag == item) {
                    return 'active';
                }
                return '';
            },
            changeItem(item) {
                if (this.activeTag != item) {
                    this.activeTag = item;
                    this.$emit('change', item == '全部' ? '' : item)
                }
            }
        },
        created() {
            if (this.list.length > 0) this.tagList = this.list
            this.activeTag = this.active || '全部'
        }
    }
</script>

<style scoped lang="scss">
    @import "reset";

    .tags-content {
        width: 1200px;
        margin: 0 auto;
        text-align: left;

        .tags-list {
            padding: 12px;
            background-image: linear-gradient(to right, #e5f1fb, #eaf6f3);
        }

        .tags-item {
            display: inline-block;
            padding: 4px 12px;
            cursor: pointer;
            border-radius: 4px;
            margin: 6px 12px;

            &.active {
                color: #fff;
                background-image: linear-gradient(to right, #2a8ad3, #48aaa4);
            }
        }
    }
</style>