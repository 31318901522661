<template>
    <div class="page-nav-bar">
        <div class="page-nav-bar-content">
            <div class="nav-list">
                <div class="nav-item" v-for="(item,index) in navList" :key="index" @click="changeItem(item, index)">
                    <span :class="index == activeNavItem?'active':''">{{item.name}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NavBar",
        props: {
            active: {
                type: [String, Number],
                default: '0'
            }
        },
        data() {
            return {
                navList: [
                    {name: '首页', url: 'pageHome'},
                    {name: '科普在身边', url: 'kpzsb_list'},
                    {name: '科普微视频', url: 'kpwsp_list'},
                    {name: '科普知识库', url: 'kpzsk_list'},
                    {name: '科普直播间', url: 'kpzbj_list'},
                    {name: '科普悦读汇', url: 'kpydh_list'},
                    {name: '科普基地行', url: 'kpjdx_list'}
                ],
                activeNavItem: '0'
            }
        },
        methods: {
            changeItem(item, index) {
                this.activeNavItem = index;
                this.$router.push({name: item.url});
                // if (index != this.activeNavItem) {
                //     this.activeNavItem = index;
                //    this.$router.push({name: item.url});
                // }
            }
        },
        created() {
            this.activeNavItem = this.active || '0'
        },
        activated() {
            this.activeNavItem = this.active || '0'
        }
    }
</script>

<style scoped lang="scss">
    @import "reset";

    .page-nav-bar {
        position: relative;
        margin-top: -40px;

        .nav-list {
            width: 1200px;
            margin: 0 auto;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
            .nav-item {
                display: inline-block;
                padding: 16px 20px;
                cursor: pointer;
                span {
                    display: inline-block;
                    width: 130px;
                    height: 40px;
                    line-height: 40px;
                    color: #666;
                    border-radius: 6px;
                    font-size: 18px;
                    text-align: center;
                    font-weight: 700;

                    &.active {
                        color: #2786D9;
                        background: rgba(39, 134, 217, 0.08);
                    }
                }
            }
        }
    }
</style>











