<template>
    <div class="page-search-list">
        <NavBar active="0"></NavBar>
        <div class="search-content">
            <a-input-search ref="userNameInput" placeholder="全站关键词搜索" @search="getList('refresh')" size="large"
                            v-model="params.Keyword" allowClear>
                <a-icon slot="prefix" type="search"/>
                <div slot="enterButton" class="search-btn">搜 索</div>
            </a-input-search>
        </div>
        <template>
            <a-list item-layout="horizontal" :dataSource="list" class="content-list">
                <template #renderItem="item">
                    <div class="item-content" @click="toDetail(item)">
                        <h4 class="item-title">{{item.Title}}</h4>
                        <div class="item-info">
                            <div class="item-img-box" v-if="item.ShowImgUrl">
                                <img :src="item.ShowImgUrl"/>
                            </div>
                            <div class="item-right-info">
                                <div class="item-desc">{{item.Brief}}</div>
                                <div class="item-other">
                                    <!--                                    <div class="item-tags">-->
                                    <!--                                        <span v-for="_item in item.Tags.split(',')" v-if="_item">{{_item}}</span>-->
                                    <!--                                    </div>-->
                                    <div class="item-source" v-if="item.Source">来源：{{item.Source}}</div>
                                </div>
                            </div>
                            <div class="item-date">{{item.PulishDate}}</div>
                        </div>
                    </div>
                </template>
            </a-list>
            <template>
                <a-pagination
                        v-model="params.page"
                        :total="listTotal"
                        :pageSize="params.limit"
                        hideOnSinglePage
                        class="page-pagination"
                        @change="changePageNum"
                />
            </template>
        </template>
    </div>
</template>

<script>
    import NavBar from "../../components/NavBar";
    import {getKeywordsSearchListApi} from "../../server";

    export default {
        name: "SearchList",
        components: {
            NavBar
        },
        data() {
            return {
                listTotal: 0,
                params: {
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 20
                },
                list: [],
            }
        },
        methods: {
            async getList(type) {
                if (type === 'refresh') {
                    this.params.page = 1;
                    this.list = [];
                }
                const _data = await getKeywordsSearchListApi(this.params);
                const data = _data.Data;
                if (data.list) {
                    this.listTotal = data.count;
                    this.list = data.list
                }
            },
            changePageNum(num) {
                this.params.page = num;
                this.getList();
            },
            toDetail(item) {
                if (item.ParentSectionId == 100022) {
                    this.$router.push({name: 'kpwsp_detail', query: {id: item.Id, type: 'search'}});
                } else if (item.ParentSectionId == 100032) {
                    this.$router.push({name: 'kpjdx_detail', query: {id: item.Id, type: 'search'}});
                } else {
                    this.$router.push({name: 'kpzsb_detail', query: {id: item.Id, type: 'search'}});
                }

            }
        },
        created() {
            console.log(this.$route.query.keywords)
            this.params.Keyword = this.$route.query.keywords;
            this.getList();
        }
    }
</script>

<style scoped lang="scss">
    .page-search-list {
        .search-content {
            width: 1100px;
            margin: 0 auto;
            margin-top: 40px;
            margin-bottom: 30px;

            .search-btn {
                width: 100px;
            }

            /deep/ input {
                /*padding: 20px;*/
            }
        }

        .content-list {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
        }

        .item-content {
            cursor: pointer;
            text-align: left;
            border-bottom: 1px solid #ddd;
            padding: 12px 0;

            .item-title {
                color: #333;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.7;
                margin-bottom: 12px;
            }

            .item-info {
                display: flex;

                .item-img-box {
                    width: 112px;
                    height: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                }

                .item-right-info {
                    flex: 1;
                    padding-left: 16px;

                    .item-desc {
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        height: 52px;
                        font-size: 16px;
                        color: #666666;
                        font-weight: 400;
                    }

                    .item-other {
                        .item-tags, .item-source {
                            display: inline-block;

                        }

                        .item-tags {
                            span {
                                display: inline-block;
                                padding: 2px 6px;
                                background: #f6f6f6;
                                font-size: 14px;
                                color: #999999;
                                font-weight: 700;
                                margin-right: 12px;
                                border: 1px solid #ccc;
                                border-radius: 4px;
                            }
                        }

                        .item-source {
                            color: #999;
                            margin-left: 20px;
                        }
                    }
                }

                .item-date {
                    width: 200px;
                    text-align: right;
                    padding-right: 12px;
                    font-size: 14px;
                    color: #999999;
                    font-weight: 700;
                }
            }
        }

        .page-pagination {
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 20px;
            text-align: center;
        }
    }
</style>