<template>
    <div class="page-top-type-list">
        <div class="type-item" :class="activeItem == item.id? 'active':''" v-for="item in list" :key="item.id"
             @click="change(item)">
            <span>{{item.name}}</span>
            <img :src="activeItem == item.id ? item.activeImgUrl : item.imgUrl" alt="">
        </div>
    </div>
</template>

<script>

    export default {
        name: "TopTypeList",
        props: {
            list: {
                type: Array,
                default: () => []
            },
            active: {
                type: [String, Number],
                default: ''
            }
        },
        data() {
            return {
                activeItem: ''
            }
        },
        watch: {
            active: {
                handler(newValue) {
                    this.activeItem = newValue;
                },
                immediate: true
            }
        },
        methods: {
            change(item) {
                if (this.activeItem == item.id) return;
                this.activeItem = item.id;
                this.$emit('change', item);
            }
        }
    }
</script>

<style scoped lang="scss">
    .page-top-type-list {
        display: flex;
        width: 1200px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 40px;

        .type-item {
            width: 22%;
            height: 80px;
            margin: 1.5%;
            padding: 0 20px;
            border: 2px solid rgba(39, 134, 217, 0.5);
            text-align: left;
            border-radius: 4px;
            line-height: 36px;

            &.active {
                background-image: linear-gradient(to right, #4ebaee, #2888d5);
                border: none;
                line-height: 40px;

                span {
                    color: #fff;
                }
            }

            span {
                display: inline-block;
                width: 96px;
                height: 31px;
                font-size: 24px;
                color: #333333;
                font-weight: 700;
                vertical-align: middle;
            }

            img {
                margin-top: 8px;
                margin-left: 30px;
            }
        }
    }
</style>