<template>
    <div class="page-kpjdx-detail">
        <NavBar active="6"></NavBar>
        <div class="page-menu" v-if="!type"><span>首页</span> / 科普基地行</div>
        <div class="detail-content">
            <h4 class="page-title">{{data.Title}}</h4>
            <div class="site-info">
                <div class="tip">参观提示</div>
                <div class="info-list">
                    <div class="info-item">
                        <div class="item-name">
                            <a-icon class="icon-font" type="environment" />
                            <span>地&nbsp;&nbsp;&nbsp;&nbsp;址</span>
                        </div>
                        <div class="item-value">{{data.Address}}</div>
                    </div>
                    <div class="info-item">
                        <div class="item-name">
                            <a-icon class="icon-font" type="clock-circle" />
                            <span>开放时间</span>
                        </div>
                        <div class="item-value">{{data.OpenTime}}</div>
                    </div>
                    <div class="info-item">
                        <div class="item-name">
                            <a-icon class="icon-font" type="team" />
                            <span>接待数量</span>
                        </div>
                        <div class="item-value">{{data.ReceptionInfo}}</div>
                    </div>
                    <div class="info-item">
                        <div class="item-name">
                            <a-icon class="icon-font" type="container" />
                            <span>预约须知</span>
                        </div>
                        <div class="item-value">{{data.BookingInfo}}</div>
                    </div>
                </div>
            </div>
            <div class="site-desc" v-html="data.Content"></div>
        </div>
    </div>
</template>

<script>
    import {getKPJDXDetailApi} from "../../server";
    import NavBar from "../../components/NavBar";
    export default {
        name: "KpjdxDetail",
        components:{NavBar},
        data() {
            return {
                type: '',
                data: {},
                id: ''
            }
        },
        methods: {
            async getKPJDXDetail() {
                const data = await getKPJDXDetailApi({
                    Id: this.id
                })
                this.data = data.Data;
            },
        },
        created() {
            this.id = this.$route.query.id;
            this.type = this.$route.query.type;
            this.getKPJDXDetail();
        }
    }
</script>

<style scoped lang="scss">
.page-kpjdx-detail{
    text-align: left;
    background: #f2f2f2;
    padding-bottom: 40px;
    .page-menu{
        width: 1200px;
        margin: 0 auto;
        padding: 30px 0;
        font-size: 16px;
        color: #999999;
        line-height: 21px;
        font-weight: 700;
        span{
            color: #2786D9;
        }
    }
    .detail-content{
        width: 1200px;
        margin: 0 auto;
        background: #fff;
        border-radius: 4px;
        padding: 32px;
        .page-title{
            font-size: 28px;
            color: #333333;
            font-weight: 700;
            padding: 20px 0;
        }
        .site-info{
            .tip{
                width: 87px;
                height: 30px;
                line-height: 30px;
                background-image: linear-gradient(to right, #2989d5, #4bae9e);
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                font-weight: 700;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
            .info-list{
                padding: 20px 30px;
                background-image: linear-gradient(to right, #e9f3fc, #edf7f5);
                .info-item{
                    display: flex;
                    margin-bottom: 10px;
                    .item-name{
                        width: 100px;
                        color: #2786D9;
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 700;
                        text-align: justify;
                        .icon-font{
                            margin-right: 6px;
                        }
                    }
                    .item-value{
                        flex: 1;
                        font-size: 16px;
                        color: #666666;
                        font-weight: 700;
                        padding-top: 2px;
                        padding-left: 10px;
                    }
                }
            }
        }
        .site-desc{
            padding: 30px 0;
            /deep/ img{
                max-width: 100%;
            }
        }
    }
}
</style>