<template>
    <div class="page-kpjdx-list">
        <NavBar active="6"></NavBar>
        <KpjdxListItem></KpjdxListItem>
    </div>
</template>

<script>
    import NavBar from "../../components/NavBar";
    import KpjdxListItem from "../../components/KpjdxListItem";
    export default {
        name: "KpjdxList",
        components:{KpjdxListItem, NavBar},
        data() {
            return {

            }
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .page-kpjdx-list{
       background: #fff;
        padding-bottom: 40px;
    }
</style>