<template>
    <div class="page-new-list">
        <div class="top">
            <div class="top-img">
                <img class="web-banner" src="../../assets/new_list_web.png"/>
                <img class="h5-banner" src="../../assets/new_list_h5.png"/>
            </div>
            <div class="search">
                <div class="header-search-content">
                    <a-input-search ref="userNameInput" placeholder="全站关键词搜索" @search="search" enter-button="搜索"
                                    allowClear>
                        <a-icon slot="prefix" type="search"/>
                    </a-input-search>
                </div>
            </div>
        </div>
        <div class="nav-content">
            <div class="nav-list1">
                <div class="nav-item" @click="changeActive('2024')" :class="navActive === '2024' ? 'active' : ''">2024年科普视频</div>
                <div class="nav-item" @click="changeActive('2023')" :class="navActive === '2023' ? 'active' : ''">2023年科普视频</div>
                <div class="nav-item" @click="changeActive('2022')" :class="navActive === '2022' ? 'active' : ''">实训基地介绍</div>
            </div>
        </div>
        <div class="list">
            <div class="list-content">
                <div class="news-item" v-for="item in list">
                    <div class="img-box">
                        <img :src="item.ShowImgUrl"/>
                        <a-icon type="play-circle" class="icon-play"/>
                        <span class="video-time">{{item.Duration}}</span>
                    </div>
                    <div class="item-info">
                        <div class="item-title">{{item.Title}}</div>
                    </div>
                </div>
            </div>
            <div class="no-more">没有更多了~</div>
        </div>
        <div class="footer">
            <div class="web-footer">
                <Footer1></Footer1>
            </div>
            <div class="h5-footer"></div>
        </div>
    </div>
</template>

<script>
    import Footer1 from "../../components/Footer1";
    import {getJKSHListApi} from "../../server";

    export default {
        name: "newList",
        components: {Footer1},
        data() {
            return {
                navActive: '2024',
                keywords: '',
                list: []
            }
        },
        methods: {
            changeActive(year) {
                this.navActive = year;
                this.getList();
            },
            async getList(type) {
                // if (type === 'refresh') this.params.page = 1;
                const _data = await getJKSHListApi({
                    "SectionId": 100089,
                    "Tag": this.navActive,
                    "Keyword": this.keywords,
                    "page": 1,
                    "limit": 200
                });
                const data = _data.Data;
                if (data.list) {
                    // this.listTotal = data.count;
                    this.list = data.list
                    console.log(this.list)
                }
            },
            search(val) {
                this.keywords = val;
                this.getList();
            }
        },
        created() {
            this.getList();
        }
    }
</script>

<style scoped lang="scss">
    .top-img{
        img{width: 100%}
    }
    .header-search-content {
        position: absolute;
        width: 250px;
        height: 60px;
        left: calc(50% + 300px);
        top: 40px;



        /deep/ .ant-input {
            /*background: rgba(255,255,255,0.2);*/
            background: rgba(120, 120, 120, 0.2);
            color: #fff;
            /*color: #333;*/
            border-color: #2a8ad3;
        }
        /deep/ .anticon-search {
            color: #fff;
        }

        /deep/ .ant-input-clear-icon {
            color: #fff;
        }
        /deep/ .ant-input::-webkit-input-placeholder {
            color: white;
        }
    }
    .nav-content {
        position: relative;
        width: 700px;
        margin: 0 auto;
        margin-top: -25px;
        height: 50px;
        border-radius: 8px;
        background: #fff;
        font-size: 16px;
        text-align: center;
        box-shadow: 0px 0px 5px 0px rgba(163, 157, 157, 0.3);
        margin-bottom: 30px;
        .nav-list1{
            display: flex;
            justify-content: center;
            .nav-item{
                width: 180px;
                margin: 0;
                height: 50px;
                line-height: 50px;
                text-align: center;

                text-align: center;
                cursor: pointer;
                &.active{
                    background: rgb(230,117,46);
                    border-radius: 8px;
                    color: #fff;
                }
            }
        }
    }
    .list{
        width: 1200px;
        margin: 0 auto;
        .list-content{
            display: flex;
            flex-wrap: wrap;
            .news-item{
                /*flex: 1;*/
                width: 360px;
                margin: 16px;
                text-align: left;
                margin-bottom: 30px;
                cursor: pointer;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: 0px 0px 5px 0px rgba(163, 157, 157, 0.3);
                overflow: hidden;
                .item-info{
                    padding: 16px;
                }
                .item-title{
                    white-space: normal;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    font-size: 18px;
                    height: 64px;
                    color: #333333;
                    font-weight: 700;
                    line-height: 1.7;
                    margin-bottom: 10px;
                }

            }
        }
        .no-more{
            margin-bottom: 30px;
        }
    }
    .img-box {
        height: 216px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .icon-play {
            position: absolute;
            left: 20px;
            bottom: 20px;
            color: #fff;
            font-size: 30px;
        }

        .video-time {
            position: absolute;
            right: 20px;
            bottom: 20px;
            color: #fff;
            font-size: 16px;
        }
    }
    @media screen and (max-width: 640px) {
        .web-banner,.web-footer{
            display: none;
        }
        .h5-banner,.h5-footer{
            display: block;
        }
        .nav-content{
            width: 90%;
            font-size: 14px;
        }
        .header-search-content{
            left: 20%;
            top: 200px;

            /deep/ .ant-input {
                /*background: rgba(255,255,255,0.2);*/
                background: rgba(120, 120, 120, 0.1);
                color: #333;
                /*color: #333;*/
                border-color: #2a8ad3;
            }
            /deep/ .anticon-search {
                color: #999;
            }

            /deep/ .ant-input-clear-icon {
                color: #999;
            }
            /deep/ .ant-input::-webkit-input-placeholder {
                color: #999;
            }
        }
        .list{
            padding-top: 30px;
        }
    }
    @media screen and (min-width: 641px) {
        .search,.web-banner,.web-footer{
            display: block;
        }
        .h5-banner,.h5-footer{
            display: none;
        }
    }
    @media screen and (max-width: 1199px) {
        .list{
            width: 90%;
        }
    }
</style>
