<template>
    <div class="components-kpjdx-list-item">
        <div class="page-title"><em></em>上海市监科普基地</div>
        <a-list :grid="{ gutter: 16, column: 3 }" :dataSource="shList" class="content-list">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a-card class="list-item1" @click="toDetail(item)">
                    <div class="img-box">
                        <img :src="item.ShowImgUrl"/>
                    </div>
                    <div class="item-info">
                        <div class="item-title">{{item.Title}}</div>
                        <div class="item-source">{{item.Source ? `来源：${item.Source}`: ''}}</div>
                        <div class="item-address">地址：{{item.Address.length > 40 ? `${item.Address.substring(0,40)}...`:
                            item.Address}}
                        </div>
                    </div>
                </a-card>
            </a-list-item>
        </a-list>
        <div class="page-title"><em></em>江苏监科普基地</div>
        <a-list :grid="{ gutter: 16, column: 3 }" :dataSource="jsList" class="content-list">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a-card class="list-item1" @click="toDetail(item)">
                    <div class="img-box">
                        <img :src="item.ShowImgUrl"/>
                    </div>
                    <div class="item-info">
                        <div class="item-title">{{item.Title}}</div>
                        <div class="item-source">{{item.Source ? `来源：${item.Source}`: ''}}</div>
                        <div class="item-address">地址：{{item.Address.length > 40 ? `${item.Address.substring(0,40)}...`:
                            item.Address}}
                        </div>
                    </div>
                </a-card>
            </a-list-item>
        </a-list>
        <div class="page-title"><em></em>浙江监科普基地</div>
        <a-list :grid="{ gutter: 16, column: 3 }" :dataSource="zjList" class="content-list">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a-card class="list-item1" @click="toDetail(item)">
                    <div class="img-box">
                        <img :src="item.ShowImgUrl"/>
                    </div>
                    <div class="item-info">
                        <div class="item-title">{{item.Title}}</div>
                        <div class="item-source">{{item.Source ? `来源：${item.Source}`: ''}}</div>
                        <div class="item-address">地址：{{item.Address.length > 40 ? `${item.Address.substring(0,40)}...`:
                            item.Address}}
                        </div>
                    </div>
                </a-card>
            </a-list-item>
        </a-list>
        <div class="page-title"><em></em>安徽监科普基地</div>
        <a-list :grid="{ gutter: 16, column: 3 }" :dataSource="ahList" class="content-list">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a-card class="list-item1" @click="toDetail(item)">
                    <div class="img-box">
                        <img :src="item.ShowImgUrl"/>
                    </div>
                    <div class="item-info">
                        <div class="item-title">{{item.Title}}</div>
                        <div class="item-source">{{item.Source ? `来源：${item.Source}`: ''}}</div>
                        <div class="item-address">地址：{{item.Address.length > 40 ? `${item.Address.substring(0,40)}...`:
                            item.Address}}
                        </div>
                    </div>
                </a-card>
            </a-list-item>
        </a-list>
    </div>
</template>

<script>
    const columns = [
        {dataIndex: 'Street', title: '所在地（街道）', align: 'center'},
        // { dataIndex: 'Street',title: '科普站',align:'center'},
        {dataIndex: 'Address', title: '地址', align: 'center'},
        {dataIndex: 'OpenTime', title: '开放时间', align: 'center'},
    ]
    import {getKPJDXListApi, getSYKPZListApi} from "../server";

    export default {
        name: "KpjdxListItem",
        data() {
            return {
                selectedType: {},
                listTotal: 0,
                params: {
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 9
                },
                list: [],
                shList: [],
                jsList: [],
                zjList: [],
                ahList: [],
                areaData: {},
                selectedTag: '',
                syList: [],
                tagList: [],
                columns
            }
        },
        methods: {
            async getList(type) {
                if (type === 'refresh') this.params.page = 1;
                const _data = await getKPJDXListApi(this.params);
                const data = _data.Data;
                if (data.list) {
                    this.listTotal = data.count;
                    this.list = data.list;
                    this.shList = data.list.filter(d => d.SectionId == 100033);
                    this.shList.push({
                        ShowImgUrl: require('../assets/shsykp.jpg'),
                        Title: '上海食药科普站',
                        Address: '上海全市16个区共100家食药科普站'
                    })
                    this.jsList = data.list.filter(d => d.SectionId == 100077);
                    this.zjList = data.list.filter(d => d.SectionId == 100078);
                    this.ahList = data.list.filter(d => d.SectionId == 100079);
                }
            },
            changeTab(item) {
                console.log(item);
                this.selectedTag = item;
                let sKey = '';
                sKey = item.indexOf("(") > -1 ? item.substring(0, item.indexOf("(")) : item;
                this.tagList.forEach((d, i) => {
                    if (d.indexOf(sKey) > -1) this.syList = this.areaData[d];
                })
            },
            toDetail(item) {
                if (item.Title == '上海食药科普站') {
                    this.$router.push({name: 'sh_detail'})
                } else {
                    this.$router.push({name: 'kpjdx_detail', query: {id: item.Id}});
                }
            }
        },
        created() {
            this.getList();
            // this.getSYList();
        }
    }
</script>

<style scoped lang="scss">
    .components-kpjdx-list-item {
        /*background: #fff;*/
        .page-title {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            padding-left: 16px;
            margin-bottom: 20px;
            margin-top: 50px;
            font-size: 22px;
            color: #333333;
            font-weight: 700;
            text-align: left;

            em {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 3px;
                background: #2a8ad3;
                border-radius: 2px;
            }
        }

        .content-list {
            width: 1200px;
            margin: 0 auto;
            overflow: hidden;

            .list-item1 {
                text-align: left;
                margin-bottom: 30px;
                cursor: pointer;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: 0px 0px 5px 0px rgba(163, 157, 157, 0.3);

                /deep/ .ant-card-body {
                    padding: 0;
                }

                .img-box {
                    height: 216px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-info {
                    padding: 12px;
                    text-align: center;
                    height: 160px;

                    .item-title {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-size: 18px;
                        color: #333333;
                        font-weight: 700;
                        line-height: 1.7;
                        padding: 8px;
                    }

                    .item-source {
                        font-size: 14px;
                        color: #999;
                        margin-bottom: 8px;
                    }

                    .item-address {
                        font-size: 14px;
                        color: #999999;
                        font-weight: 700;
                    }
                }
            }
        }

        .sy-content {
            width: 1200px;
            margin: 0 auto;
            /*margin-bottom: 40px;*/
            border-radius: 4px;
            overflow: hidden;

            .sy-tab {
                background-image: linear-gradient(to right, #2786d8, #4fb298);

                /deep/ .ant-tabs-tab {
                    color: #fff;
                    opacity: 0.5;
                    margin-right: 8px;
                    font-weight: bold;
                    padding: 16px 8px;
                }

                /deep/ .ant-tabs-bar {
                    border-bottom: none;
                    margin: 0;
                }

                /deep/ .ant-tabs-tab-active {
                    color: #fff;
                    opacity: 1;
                }

                /deep/ .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
                    background-color: #fff;
                }
            }

            .sy-tab-content {
                background: #e6f2fa;

                .sy-table {
                    width: 1140px;
                    margin: 0 auto;
                    padding-bottom: 60px;
                    padding-top: 20px;
                }

                /deep/ th {
                    font-size: 20px;
                    color: #2786D9;
                    font-weight: 700;
                    background: transparent;
                    border-bottom: none;
                }

                /deep/ td {
                    font-size: 16px;
                    color: #666666;
                    font-weight: 700;
                    background: #fff;
                    border-bottom: 16px solid #e6f2fa;
                }
            }
        }
    }
</style>