<template>
    <div class="page-shDetail-content">
        <NavBar active="6"></NavBar>
        <div class="page-title"><em></em>上海食药科普站</div>
        <div class="sy-content">
            <div class="sy-tab">
                <a-tabs default-active-key="1" @change="changeTab">
                    <a-tab-pane class="sy-tab-item" v-for="item in tagList" :key="item" :tab="item"></a-tab-pane>
                </a-tabs>
            </div>
            <div class="sy-tab-content">
                <a-table :columns="columns" :data-source="syList" rowKey="Id" :pagination="false"
                         class="sy-table"></a-table>
            </div>
        </div>
    </div>
</template>

<script>
    const columns = [
        {dataIndex: 'Street', title: '所在地（街道）', align: 'center'},
        // { dataIndex: 'Street',title: '科普站',align:'center'},
        {dataIndex: 'Address', title: '地址', align: 'center'},
        {dataIndex: 'OpenTime', title: '开放时间', align: 'center'},
    ]
    import {getSYKPZListApi} from "../../server";
    import NavBar from "../../components/NavBar";
    export default {
        name: "SHDetail",
        components:{NavBar},
        data() {
            return {
                columns,
                syList:[],
                tagList: [],
                areaData: {},
                selectedTag: '',
            }
        },
        methods: {
            async getSYList() {
                const data = await getSYKPZListApi({});
                const _data = data.Data;
                if (_data) {
                    Object.keys(_data).forEach((d, index) => {
                        const item = `${d.split('区')[0].split('新')[0]}(${_data[d].length})`;
                        this.tagList.push(item);
                        this.areaData[item] = _data[d];
                        if (index === 0) {
                            this.selectedTag = item;
                            this.syList = _data[d];
                        }
                    });
                }
            },
            changeTab(item) {
                console.log(item);
                this.selectedTag = item;
                let sKey = '';
                sKey = item.indexOf("(") > -1 ? item.substring(0, item.indexOf("(")) : item;
                this.tagList.forEach((d, i) => {
                    if (d.indexOf(sKey) > -1) this.syList = this.areaData[d];
                })
            },
        },
        created() {
            this.getSYList();
        }
    }
</script>

<style scoped lang="scss">
.page-shDetail-content{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    padding-left: 16px;
    margin-bottom: 20px;
    font-size: 22px;
    color: #333333;
    font-weight: 700;
    text-align: left;
    .page-title{
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 16px;
        margin-bottom: 20px;
        margin-top: 50px;
        font-size: 22px;
        color: #333333;
        font-weight: 700;
        text-align: left;
        em{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 3px;
            background: #2a8ad3;
            border-radius: 2px;
        }
    }
    .sy-content{
        width: 1200px;
        margin: 0 auto;
        /*margin-bottom: 40px;*/
        border-radius: 4px;
        overflow: hidden;
        .sy-tab{
            background-image: linear-gradient(to right, #2786d8, #4fb298);
            /deep/ .ant-tabs-tab{
                color: #fff;
                opacity: 0.5;
                margin-right: 8px;
                font-weight: bold;
                padding: 16px 8px;
            }
            /deep/ .ant-tabs-bar{
                border-bottom: none;
                margin: 0;
            }
            /deep/ .ant-tabs-tab-active{
                color: #fff;
                opacity: 1;
            }
            /deep/ .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
                background-color: #fff;
            }
        }
        .sy-tab-content {
            background: #e6f2fa;
            .sy-table{
                width: 1140px;
                margin: 0 auto;
                padding-bottom: 60px;
                padding-top: 20px;
            }
            /deep/ th{
                font-size: 20px;
                color: #2786D9;
                font-weight: 700;
                background: transparent;
                border-bottom: none;
            }
            /deep/ td{
                font-size: 16px;
                color: #666666;
                font-weight: 700;
                background: #fff;
                border-bottom: 16px solid #e6f2fa;
            }
        }
    }
}
</style>