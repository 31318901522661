<template>
    <div class="page-index">
        <NavBar active="0"></NavBar>
        <div class="index-content">

            <div class="kpzsb-content page-item-content">
                <IndexTitle :data="title.data1"></IndexTitle>
                <div class="content-list1">
                    <div class="left-content">
                        <img :src="selected.data1.ShowImgUrl"/>
                        <div class="info-content">
                            <!--                            <div class="tag" v-if="selected.data1.Tags">-->
                            <!--                                <span v-for="item in selected.data1.Tags.split(',')" v-if="item">{{item
                            }}</span>-->
                            <!--                            </div>-->
                            <div class="title">{{selected.data1.Title}}</div>
                            <div class="date">{{selected.data1.PulishDate}}</div>
                        </div>
                    </div>
                    <div class="right-content">
                        <div v-for="item in list.data1" :key="item.Id" @mousemove="changeSelect1(item)"
                             @click="toDetail('kpzsb_detail',item.Id)" class="item"
                             :class="item.Id == selected.data1.Id ? 'active':''">
                            <div class="item-left">
                                <em></em>
                                <div class="left-year">{{item.PulishDate.substring(0, 4)}}</div>
                                <div class="left-date">{{item.PulishDate.substring(5, 11).replace('-','/')}}</div>
                            </div>
                            <div class="item-middle">
                                <p class="item-title">{{item.Title}}</p>
                                <p class="source">{{item.Source || ''}}</p>
                            </div>
                            <div class="item-right"><span>»</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kpwsp-content page-item-content">
                <div class="kpwsp-bg">
                    <IndexTitle :data="title.data2"></IndexTitle>
                    <a-list :grid="{ gutter: 16, column: 3 }" :dataSource="list.data2" class="content-list2">
                        <a-list-item slot="renderItem" slot-scope="item, index">
                            <Item1 :data="item"></Item1>
                        </a-list-item>
                    </a-list>
                </div>
            </div>
            <div class="kpzsk-content page-item-content">
                <IndexTitle :data="title.data3"></IndexTitle>
                <div class="content-list3" v-if="selected.data3.Id">
                    <div class="left-content">
                        <h4 class="title">{{selected.data3.Title}}</h4>
                        <div class="left-flex">
                            <div class="flex-img">
                                <img :src="selected.data3.ShowImgUrl"/>
                            </div>
                            <div class="flex-info">
                                <div class="info-content">{{selected.data3.Brief}}</div>
                            </div>
                        </div>
                        <div class="left-date">{{selected.data3.PulishDate.replace(/-/g,'/')}}</div>
                    </div>
                    <div class="right-content">
                        <div v-for="(item, index) in list.data3" :key="item.Id" @mousemove="changeSelect3(item)"
                             @click="toDetail('kpzsk_detail',item.Id)" class="item"
                             :class="item.Id == selected.data3.Id ? 'active':''">
                            <div class="item-left">
                                <em></em>
                                <div class="left-index">0{{index + 1}}</div>
                            </div>
                            <div class="item-middle">{{item.Title}}</div>
                            <div class="item-right"><span>»</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="zbj-content page-item-content">
                <IndexTitle :data="title.data0"></IndexTitle>
                <div class="content-list0">
                    <div class="left-content">
                        <img :src="selected.data0.ShowImgUrl"/>
                        <div class="info-content">
                            <!--                            <div class="tag" v-if="selected.data1.Tags">-->
                            <!--                                <span v-for="item in selected.data1.Tags.split(',')" v-if="item">{{item
                            }}</span>-->
                            <!--                            </div>-->
                            <div class="title">{{selected.data0.Title}}</div>
                            <div class="date">{{selected.data0.PulishDate}}</div>
                        </div>
                    </div>
                    <div class="right-content">
                        <div v-for="item in list.data0" :key="item.Id" @mousemove="changeSelect0(item)"
                             @click="toDetail('kpzbj_detail',item)" class="item"
                             :class="item.Id == selected.data0.Id ? 'active':''">
                            <div class="item-left">
                                <div class="tag"></div>
                                <img :src="item.ShowImgUrl"/>
                            </div>
                            <div class="item-middle">
                                <p class="item-title">{{item.Title}}</p>
                                <p class="to-detail">{{item.SectionId === 100065 ? '立即观看' : '查看回放'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kpydh-content page-item-content">
                <IndexTitle :data="title.data4"></IndexTitle>
                <a-list :grid="{ gutter: 16, column: 2 }" :dataSource="list.data4" class="content-list4">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <a-card class="list-item1" @click="toDetail('kpydh_detail',item.Id)">
                            <div class="item-content">
                                <div class="img-box">
                                    <img :src="item.ShowImgUrl"/>
                                </div>
                                <div class="item-info">
                                    <div class="item-title">{{item.Title}}</div>
                                    <div class="item-desc">{{item.Brief}}</div>
                                    <div class="item-other">
                                        <div class="item-source">{{item.Source}}</div>
                                        <div class="item-date">{{item.PulishDate}}</div>
                                    </div>
                                </div>
                            </div>
                        </a-card>
                    </a-list-item>
                </a-list>
            </div>
            <div class="kpjdx-content page-item-content">
                <div class="kpjdx-bg">
                    <IndexTitle :data="title.data5"></IndexTitle>
                    <KpjdxListItem></KpjdxListItem>
                    <!--                    <a-list :grid="{ gutter: 16, column: 3 }" :dataSource="list.data5" class="content-list5">-->
                    <!--                        <a-list-item slot="renderItem" slot-scope="item, index">-->
                    <!--                            <a-card class="list-item1" @click="toDetail('kpjdx_detail', item.Id)">-->
                    <!--                                <div class="img-box">-->
                    <!--                                    <img :src="item.ShowImgUrl"/>-->
                    <!--                                </div>-->
                    <!--                                <div class="item-info">-->
                    <!--                                    <div class="item-title">{{item.Title}}</div>-->
                    <!--                                    <div class="item-address">地址：{{item.Address}}</div>-->
                    <!--                                </div>-->
                    <!--                            </a-card>-->
                    <!--                        </a-list-item>-->
                    <!--                    </a-list>-->
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import NavBar from "../components/NavBar";
    import {
        getKPZBJListApi,
        getKPJDXListApi,
        getKPWSPListApi,
        getKPYDHListApi,
        getKPZSBListApi,
        getKPZSKListApi,
        getSYKPZListApi, getZBJListApi
    } from "../server";
    import IndexTitle from "../components/IndexTitle";
    import Item1 from "../components/Item1";
    import KpjdxListItem from "../components/KpjdxListItem";

    export default {
        name: "Index",
        components: {KpjdxListItem, IndexTitle, NavBar, Item1},
        data() {
            return {
                title: {
                    data0: {
                        title: '科普直播间',
                        titleEn: 'Popular Science live',
                        moreName: 'kpzbj_list',
                        iconType: 'play-square'
                    },
                    data1: {
                        title: '科普在身边',
                        titleEn: 'Popular Science Articles',
                        moreName: 'kpzsb_list',
                        iconType: 'read'
                    },
                    data2: {
                        title: '科普微视频',
                        titleEn: 'Popular Science Video',
                        moreName: 'kpwsp_list',
                        iconType: 'video-camera'
                    },
                    data3: {
                        title: '科普知识库',
                        titleEn: 'Popular Science Knowledge',
                        moreName: 'kpzsk_list',
                        iconType: 'bulb'
                    },
                    data4: {
                        title: '科普悦读汇',
                        titleEn: 'Popular Science Bookshelf',
                        moreName: 'kpydh_list',
                        iconType: 'book'
                    },
                    data5: {
                        title: '科普基地行',
                        titleEn: 'Popular Science Base',
                        moreName: 'kpjdx_list',
                        iconType: 'environment'
                    },
                },
                list: {
                    data0: [],
                    data1: [],
                    data2: [],
                    data3: [],
                    data4: [],
                    data5: [],
                },
                selected: {
                    data0: {},
                    data1: {},
                    data3: {}
                }
            }
        },
        methods: {
            changeSelect0(item) {
                this.selected.data0 = item;
            },
            changeSelect1(item) {
                this.selected.data1 = item;
            },
            changeSelect3(item) {
                this.selected.data3 = item;
            },
            async getKPZBJList() {
                const data = await getZBJListApi({
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 4
                })
                this.list.data0 = data.Data.list;
                if (data.Data.list.length === 0) return;
                if (data.Data.list.length == 1) {
                    this.selected.data0 = data.Data.list[0];
                } else {
                    this.selected.data0 = data.Data.list[1];
                }
            },
            async getKPZSBList() {
                const data = await getKPZSBListApi({
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 5
                })
                this.list.data1 = data.Data.list;
                if (data.Data.list.length === 0) return;
                if (data.Data.list.length == 1) {
                    this.selected.data1 = data.Data.list[0]
                } else {
                    this.selected.data1 = data.Data.list[1]
                }
            },
            async getKPWSPList() {
                const data = await getKPWSPListApi({
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 6
                });
                this.list.data2 = data.Data.list;
            },
            async getKPZSKList() {
                const data = await getKPZSKListApi({
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 4
                })
                this.list.data3 = data.Data.list;
                if (data.Data.list.length === 0) return;
                if (data.Data.list.length == 1) {
                    this.selected.data3 = data.Data.list[0]
                } else {
                    this.selected.data3 = data.Data.list[1]
                }
            },

            async getKPYDHList() {
                const data = await getKPYDHListApi({
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 6
                })
                this.list.data4 = data.Data.list;
            },

            async getKPJDXList() {
                const data = await getKPJDXListApi({
                    "SectionId": 0,
                    "Tag": "",
                    "Keyword": "",
                    "page": 1,
                    "limit": 4
                })
                this.list.data5 = data.Data.list;
            },
            toDetail(type, id) {
                if (type === 'kpzbj_detail') {
                    window.open(id.PCLinkUrl);
                    return;
                }
                this.$router.push({name: type, query: {id: id}})
            }
        },
        created() {
            this.getKPZBJList();
            this.getKPZSBList();
            this.getKPWSPList();
            this.getKPZSKList();
            this.getKPYDHList();
            // this.getKPJDXList();
        }
    }
</script>

<style scoped lang="scss">
    .page-index {
        .page-item-content {
            padding-top: 30px;
        }

        .content-list0,
        .content-list1,
        .content-list2,
        .content-list3,
        .content-list4,
        .content-list5 {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
        }

        .kpwsp-content, .kpjdx-content {
            background: #e9f3fa;
        }

        .kpzsb-content {
            padding-bottom: 10px;
        }

        .zbj-content {
            margin-top: 20px;
            background: url("../assets/index-bg0.svg") no-repeat center center #0E1C45;
        }

        .content-list0 {
            display: flex;
            /*background: url("../assets/index-bg0.svg") no-repeat bottom left #0E1C45;*/
            /*background-position-x: 190px;*/
            padding-bottom: 60px;

            .left-content {
                position: relative;
                width: 820px;

                img {
                    width: 820px;
                    height: 460px;
                }

                .info-content {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 120px;
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                    padding: 0 40px;
                    text-align: left;
                    padding-top: 20px;

                    .tag {
                        margin-bottom: 5px;

                        span {
                            display: inline-block;
                            margin-right: 16px;
                            font-size: 14px;
                            color: #FFFFFF;
                            font-weight: 700;
                            text-align: center;
                            border-radius: 2px;
                            padding: 2px 6px;
                            background-image: linear-gradient(to right, #2a8ad3, #4bae9e);
                        }
                    }

                    .title {
                        font-size: 24px;
                        color: #FFFFFF;
                        font-weight: 700;
                        margin-bottom: 5px;
                    }

                    .date {
                        font-size: 14px;
                        color: #FFFFFF;
                        font-weight: 700;
                    }
                }
            }

            .right-content {
                flex: 1;
                padding: 10px 20px;
                background-image: linear-gradient(to right bottom, #2786d8, #4fb398);

                .item {
                    display: flex;
                    cursor: pointer;
                    color: #fff;

                    .item-left {
                        position: relative;
                        width: 160px;
                        padding-top: 16px;
                        text-align: center;
                        color: #333333;

                        .tag {
                            position: absolute;
                            display: none;
                            border: 10px solid #fff;
                            border-top-color: transparent;
                            border-left-color: transparent;
                            border-bottom-color: transparent;
                            left: -18px;
                            top: 50px;
                        }

                        img {
                            width: 160px;
                            height: 90px;
                        }
                    }

                    .item-middle {
                        flex: 1;
                        padding-top: 20px;
                        padding-left: 16px;
                        font-size: 16px;
                        font-weight: 700;
                        text-align: left;

                        p {
                            margin: 0;
                        }

                        .item-title {
                            word-break: break-all;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }

                        .to-detail {
                            margin-top: 10px;
                            font-size: 14px;
                        }
                    }

                    &.active {
                        .item-left {
                            img {
                                border: 3px solid #fff;
                                border-radius: 4px;
                            }

                            .tag {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .content-list1 {
            display: flex;
            background: url("../assets/index-bg1.png") no-repeat bottom left;
            background-position-x: 190px;
            padding-bottom: 60px;

            .left-content {
                position: relative;
                width: 590px;

                img {
                    width: 588px;
                    height: 441px;
                    border: 1px solid #eee;
                    border-radius: 4px;
                }

                .info-content {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 120px;
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                    padding: 0 40px;
                    text-align: left;

                    .tag {
                        margin-bottom: 5px;

                        span {
                            display: inline-block;
                            margin-right: 16px;
                            font-size: 14px;
                            color: #FFFFFF;
                            font-weight: 700;
                            text-align: center;
                            border-radius: 2px;
                            padding: 2px 6px;
                            background-image: linear-gradient(to right, #2a8ad3, #4bae9e);
                        }
                    }

                    .title {
                        font-size: 24px;
                        color: #FFFFFF;
                        font-weight: 700;
                        margin-bottom: 5px;
                    }

                    .date {
                        font-size: 14px;
                        color: #FFFFFF;
                        font-weight: 700;
                    }
                }
            }

            .right-content {
                flex: 1;
                padding-left: 20px;

                .item {
                    display: flex;
                    height: 87px;
                    border-bottom: 1px solid #ddd;
                    cursor: pointer;

                    .item-left {
                        width: 80px;
                        padding-top: 16px;
                        text-align: center;
                        color: #333333;

                        span {
                            display: none;
                        }

                        .left-year {
                            font-size: 14px;
                            font-weight: 700;
                            margin-bottom: 4px;
                        }

                        .left-date {
                            font-size: 20px;
                            line-height: 28px;
                            font-weight: 700;
                        }
                    }

                    .item-middle {
                        flex: 1;
                        padding-top: 20px;
                        padding-left: 30px;
                        font-size: 16px;
                        color: #333333;
                        font-weight: 700;
                        text-align: left;

                        p {
                            margin: 0;
                        }

                        .item-title {
                            width: 440px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        .source {
                            text-align: right;
                            font-size: 14px;
                            margin-top: 6px;
                            color: #999;
                        }
                    }

                    .item-right {
                        width: 60px;
                        padding-top: 11px;
                        font-size: 40px;

                        span {
                            display: none;
                            color: #2786D9;
                        }
                    }

                    &.active {
                        background-image: linear-gradient(to right, #fbfdff, #e5f0fa);

                        .item-left {
                            color: #fff;
                            position: relative;
                            background-image: linear-gradient(to bottom, #4db8ed, #2a8ada);

                            em {
                                display: block;
                                position: absolute;
                                right: -20px;
                                top: 34px;
                                border: 10px solid;
                                border-color: transparent transparent transparent #3ca2e4;
                            }
                        }

                        .item-middle {
                            color: #2786D9;

                            .source {
                                color: #2786D9;
                            }
                        }

                        .item-right {
                            span {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        .kpwsp-content {

            .kpwsp-bg {
                background: url('../assets/index-bg2.png') no-repeat center center;
                background-size: 1756px 480px;
            }
        }

        .content-list2 {

        }

        .content-list3 {
            display: flex;

            .left-content {
                width: 620px;
                text-align: left;
                border: 2px solid rgba(39, 134, 217, 0.5);
                border-radius: 4px;
                padding: 30px;
                box-shadow: 5px 5px 0px 3px rgba(39, 134, 217, 0.1);

                .title {
                    font-size: 24px;
                    color: #333333;
                    font-weight: 700;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin-bottom: 16px;
                }

                .left-flex {
                    display: flex;

                    .flex-img {
                        width: 191px;
                        height: 144px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                        }
                    }

                    .flex-info {
                        flex: 1;
                        padding-left: 24px;
                        font-size: 16px;
                        color: #333333;
                        text-align: justify;
                        font-weight: 400;
                        line-height: 1.7;

                        .info-content {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            -webkit-line-clamp: 5;
                        }
                    }
                }

                .left-date {
                    margin-top: 16px;
                    text-align: right;
                    font-size: 16px;
                    color: #666666;
                    font-weight: 700;
                }
            }

            .right-content {
                flex: 1;
                padding-left: 20px;

                .item {
                    display: flex;
                    height: 75px;
                    border-bottom: 1px solid #ddd;
                    cursor: pointer;

                    .item-left {
                        position: relative;
                        width: 60px;
                        text-align: center;
                        color: #2786D9;

                        em {
                            position: absolute;
                            display: block;
                            left: 15px;
                            top: 15px;
                            width: 36px;
                            height: 36px;
                            background: #2786D9;
                            opacity: 0.1;
                        }

                        .left-index {
                            position: absolute;
                            left: 10px;
                            top: 10px;
                            width: 36px;
                            height: 36px;
                            line-height: 36px;
                            font-size: 18px;
                            color: #2786D9;
                            font-weight: 700;
                            border: 2px solid rgba(51, 156, 247, 0.3);
                        }
                    }

                    .item-middle {
                        flex: 1;
                        padding-top: 20px;
                        padding-left: 30px;
                        font-size: 16px;
                        color: #333333;
                        font-weight: 700;
                        text-align: left;
                    }

                    .item-right {
                        width: 60px;
                        padding-top: 4px;
                        font-size: 40px;

                        span {
                            display: none;
                            color: #2786D9;
                        }
                    }

                    &.active {
                        background-image: linear-gradient(to right, #fbfdff, #e5f0fa);

                        .item-middle {
                            color: #2786D9;
                        }

                        .item-right {
                            span {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        .kpydh-content {
            background: url('../assets/index-bg4.png') no-repeat center center;
            background-size: 1477px 693px;
            background-position-y: 100px;
        }

        .content-list4 {
            width: 1200px;
            margin: 0 auto;

            .list-item1 {
                text-align: left;
                margin-bottom: 30px;
                cursor: pointer;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: 0px 0px 5px 0px rgba(163, 157, 157, 0.3);

                /deep/ .ant-card-body {
                    padding: 0;
                }

                .item-content {
                    display: flex;
                    padding: 30px;
                }

                .img-box {
                    width: 180px;
                    height: 180px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-info {
                    flex: 1;
                    padding: 0 16px;

                    .item-title {
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        font-size: 18px;
                        height: 64px;
                        color: #333333;
                        font-weight: 700;
                        line-height: 1.7;
                        margin-bottom: 10px;
                    }

                    .item-desc {
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 3;
                        height: 66px;
                        margin-bottom: 20px;
                    }

                    .item-other {
                        display: flex;

                        .item-source {
                            width: 50%;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #666666;
                            font-weight: 600;
                        }

                        .item-date {
                            width: 50%;
                            text-align: right;
                            font-weight: bold;
                            color: #999;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .kpjdx-content {
            background: #e9f3fa;

            .kpjdx-bg {
                background: url('../assets/index-bg5.png') no-repeat center bottom;
                background-size: 1753px 400px;
                background-position-y: 420px;
            }
        }

        .content-list5 {
            width: 1200px;
            margin: 0 auto;

            .list-item1 {
                text-align: left;
                margin-bottom: 30px;
                cursor: pointer;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: 0px 0px 5px 0px rgba(163, 157, 157, 0.3);

                /deep/ .ant-card-body {
                    padding: 0;
                }

                .img-box {
                    height: 216px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-info {
                    padding: 16px;
                    text-align: center;
                    height: 160px;

                    .item-title {
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        font-size: 18px;
                        height: 64px;
                        color: #333333;
                        font-weight: 700;
                        line-height: 1.7;
                        padding: 12px;
                    }

                    .item-address {
                        font-size: 14px;
                        color: #999999;
                        font-weight: 700;
                    }
                }
            }
        }
    }
</style>